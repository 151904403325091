import Insurance from "store/models/Insurance";

import StoreActions from "store/actions";

const storeActions = new StoreActions(Insurance);

export default class InsuranceActions {

  async get(insuranceId, limitData = null) {
    return await storeActions.query(
      {
        queryName: "getInsurance",
        relativeUrl: "/insurance/get-insurance",
        limitData,
      },
      "To fetch order.",
      {
        id: insuranceId,
      }
    );
  }
  
  async create(variables) {
    return await storeActions.mutation(
      {
        relativeUrl: "/insurance/create-insurance",
        queryName: "createInsurance",
        queries: variables.queries,
      },
      "To create insurance.",
      { ...variables.params }
    );
  }
  async update(variables) {
    return await storeActions.mutation(
      {
        relativeUrl: "/insurance/update-insurance",
        queryName: "updateInsuranceDetails",
        queries: variables.queries || [],
        childQueries: variables.childQueries || [],
      },
      "To update insurance.",
      { ...variables.params }
    );
  }
}
