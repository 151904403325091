import InsuranceCompany from "store/models/InsuranceCompany";
import StoreActions from "store/actions";

const storeActions = new StoreActions(InsuranceCompany);
export default class InsuranceCompanyActions {
  async getInsuranceCompanyList(variables, desciption = "") {
    return await storeActions.query(
      {
        queryName: "insuranceCompanies",
        relativeUrl: "/insurance-company/all-list",
        ...variables,
      },
      desciption.length ? desciption : "to load insurance company list."
    );
  }

  async activate(variables) {
    const { id, limitData = [] } = variables;
    return await storeActions.mutation({
      queryName: "activateInsuranceCompany",
      relativeUrl: "/payor/activate",
      limitData,
      shouldNotReturnId: true,
    }, "To activate payor.", { id });
  }

  async deactivate(variables) {
    const { id, limitData = [] } = variables;
    return await storeActions.mutation({
      queryName: "deactivateInsuranceCompany",
      relativeUrl: "/payor/deactivate",
      limitData,
      shouldNotReturnId: true,
    }, "To deactivate payor.", { id });
  }

  async update(variables) {
    return await storeActions.mutation(
      {
        queryName: "updateInsuranceCompanyDetails",
        relativeUrl: "/payor/update-payor-details",
        queries: variables.queries,
        collectionQueries: variables.collectionQueries,
      },
      "To update payor.",
      {
        insuranceCompanyId: variables.insuranceCompanyId,
      }
    );
  }
  async create(variables) {
    return await storeActions.mutation(
      {
        queryName: "createInsuranceCompany",
        relativeUrl: "/payor/create-payor",
        queries: variables.queries,
        collectionQueries: variables.collectionQueries,
      },
      "To create payor.",
      {
        name: variables.name,
        insuranceSourceType: variables.insuranceSourceType,
        insuranceCompanyId: variables.insuranceCompanyId,
        payorGroup: variables.payorGroup,
        payorSubGroup: variables.payorSubGroup,
      }
    );
  }
}
