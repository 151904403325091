import TestOrdered from "store/models/TestOrdered";

import StoreActions from "store/actions";

const storeActions = new StoreActions(TestOrdered);

export default class TestOrderedActions {
  async update(variables) {
    return await storeActions.mutation(
      {
        relativeUrl: "/test-ordered/update-test-ordered-details",
        queryName: "updateTestOrderedDetails",
        queries: variables.queries,
      },
      "To update order.",
      {
        testOrderedId: variables.testOrderedId,
      }
    );
  }
}
