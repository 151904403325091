import Patient from "store/models/Patient";

import StoreActions from "store/actions";

const storeActions = new StoreActions(Patient);

export default class PatientActions {
  async update(variables) {
    return await storeActions.mutation(
      {
        relativeUrl: "/patient/update-patient-details",
        queryName: "updatePatientDetails",
        queries: variables.queries,
        collectionQueries: variables.collectionQueries,
      },
      "To update patient.",
      {
        patientId: variables.patientId,
      }
    );
  }
}
