import Insured from "store/models/Insured";

import StoreActions from "store/actions";

const storeActions = new StoreActions(Insured);

export default class InsuredActions {
  async create(variables) {
    return await storeActions.mutation(
      {
        relativeUrl: "/insured/create-insured",
        queryName: "createInsured",
        queries: variables.queries || {},
        collectionQueries: variables.collectionQueries || [],
      },
      "To create insured.",
      {
        ...variables.params,
      }
    );
  }
}
