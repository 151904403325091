import MSP from "store/models/MSP";

import StoreActions from "store/actions";

const storeActions = new StoreActions(MSP);
export default class MSPActions {
  async getMSPList(variables) {
    return await storeActions.query(
      {
        queryName: "msps",
        relativeUrl: "/msp/all-list",
        ...variables,
      },
      "to load msp list."
    );
  }
}
