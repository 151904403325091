import PatientSignature from "store/models/PatientSignature";

import StoreActions from "store/actions";

const storeActions = new StoreActions(PatientSignature);

export default class PatientSignatureActions {
  async create(variables) {
    return await storeActions.mutation(
      {
        relativeUrl: "/patient-signature/create-patient-signature",
        queryName: "createPatientSignature",
      },
      "To create patient signature.",
      {
        ...variables,
      }
    );
  }

  async update(variables) {
    return await storeActions.mutation(
      {
        relativeUrl: "/patient-signature/update-patient-signature-details",
        queryName: "updatePatientSignatureDetails",
        queries: variables.queries,
      },
      "To update patient signature.",
      {
        patientSignatureId: variables.patientSignatureId,
      }
    );
  }
}
